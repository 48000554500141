.FileUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.progress {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 10px;
  border-radius: 20px;
}

.progress-bar {
  height: 24px;
  text-align: center;
  color: white;
  line-height: 24px;
  transition: width 0.2s;
  border-radius: 20px;
  font-size: 16px;
  font-family: Gill Sans, sans-serif;
  font-style: italic;
}

.progress-bar.initial {
  background-color: white;
  color: black; 
  font-size: 15px;
  font-family: Gill Sans, sans-serif;
  font-style: italic;
}

.progress-bar.active {
  background-color: #4caf50; 
}

.message {
  margin-top: 20px;
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-family: Gill Sans, sans-serif;
  font-style: italic;
}

.error-details {
  margin-top: 20px;
  color: rgb(255, 255, 255);
  font-family: Gill Sans, sans-serif;
}
