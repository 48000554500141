.App {
  font-family: Gill Sans, sans-serif;
  text-align: center;
  padding: 20px;
  font-size: 25px;
  margin: auto;
}

.App h1 {
  color: #ffffff;
  padding: 10px;
  font-size: 40px;
}

label {
  display: block;
  margin: 10px 0 5px;
  font-size: 20px;
  font-family: Gill Sans, sans-serif;
}
option{
  font-family: Gill Sans, sans-serif;
  text-align: center;
  font-size: 15px;
}
select,
input[type="text"] {
  font-family: Gill Sans, sans-serif;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ffffff;
  border-radius: 10px;
  width: 250px;
  font-size: 15px;
}

select {
  cursor: pointer;
}
.card {
  background-image: url("https://media.istockphoto.com/id/1371307885/es/vector/verde-azulado-verde-azulado-desenfocado-degradado-de-movimiento-borroso-vector-de-fondo.jpg?s=612x612&w=0&k=20&c=3OEOO2X9p78AF3yMJZdppNkUoHcDK6fe_FB3rR8iER0=");
  background-size: cover;
  background-position: center;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: auto;
  width: 400px;
  color: #ffffff;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.card h1 {
  margin-bottom: 20px;
  font-size: 40px;
}

.card label {
  display: block;
  margin-bottom: 10px;
}
body {
  background-image: url("/background2.jpg");
  background-size: cover;
  background-position: center;
}

.logo {
  width: 400px; 
  height: auto;
}